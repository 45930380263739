<template>
  <div>
    <div class="card w-100 p-0">
      <b-row class="p-0 m-0 w-100">
        <b-col lg="5" class="d-lg-flex align-items-center p-5">
          <b-row class="d-lg-flex align-items-center">
            <div
              class="w-100 d-lg-flex align-items-center justify-content-center px-5"
            >
              <h1 class="text-primary text-center">
                Welcome to Osama Akl Academy
              </h1>
            </div>
            <div
              class="w-100 d-flex align-items-center justify-content-center px-5"
            >
              <b-button
                to="list-courses"
                variant="gradient-primary"
                :to="{ name: 'list-courses' }"
                >See All Courses</b-button
              >
            </div>
          </b-row>
        </b-col>
        <b-col lg="7" class="d-none d-lg-flex align-items-center p-5">
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img fluid :src="imgUrl" alt="Login V2" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import authApi from "@/apiServices/authApi";
import * as Cookies from "js-cookie";
export default {
  components: {
    BCardBody,

    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          authApi
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              if (response.data) {
                Cookies.set("token", response.token);
                localStorage.setItem("user", JSON.stringify(response.data));
                this.$router.push("/");

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "welcome  " + response.data.name,
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
