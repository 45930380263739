<template>
  <div>
    <b-row>
      <!-- <totalSales /> -->
      <!-- <iframe
        v-if="mounedObj"
        src="https://player.vdocipher.com/playerAssets/1.x/vdo/embed/index.html#otp=20160313versASE323tsaJV9QDQqd0SH1DJBpJ2N345blARrthkSnOnn4aLMqCQe&playbackInfo=eyJ2aWRlb0lkIjoiMjc1ZTNlYjk3N2YzYjY0ODU5NDQ3ZTFmY2YzYzI2ZDUifQ=="
        allowFullScreen="true"
        allow="encrypted-media"
      ></iframe> -->
      <homepage />
      <!-- <analytics class="w-100" /> -->
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import totalSales from "@/views/home pages/total sales.vue";
import homepage from "@/views/home pages/homepage.vue";
import analytics from "@/views/analytics/Analytics.vue";
import statisticApi from "@/apiServices/statisticsApi";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    totalSales,
    analytics,
    homepage,
  },
  data() {
    return {
      mounedObj: false,
    };
  },
  mounted() {
    this.mounedObj = true;
  },
  created() {
    // this.getStatistics();
  },
  methods: {
    getStatistics() {
      statisticApi.getStatistics().then((response) => {
        if (response) {
          localStorage.setItem("statistics", JSON.stringify(response.data));
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Welcome",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
  },
};
</script>

<style></style>
