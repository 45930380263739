import axiosApi from "../axios";
export default {
  async getStatistics(pramas) {
    return await axiosApi
      .get(`/dashboard/statistics/`)
      .then((res) => {
        console.log(res + "this is data ");
        return res;
      })
      .catch((e) => {
        console.log(
          JSON.stringify(e.response?.data?.errors) + "this is an error "
        );
        return e.response?.data?.errors;
      });
  },
};
